import request from '../../utils/request'

export default class UserRequest {
  // 获取列表
  async listPost (data) {
    return await request.post('/Business/Contribute/Select', data)
  }

  // 添加操作
  async addPost (data) {
    return request.post('/Business/Contribute/Insert', data)
  }

  // 编辑操作
  async editPost (data) {
    return request.post('/Business/Contribute/Edit', data)
  }

  // 删除操作
  async deletePost (data) {
    return request.post('/Business/Contribute/Delete', data)
  }

  // 核销操作
  async handlePost (data) {
    return request.post('/Business/Contribute/Handle', data)
  }

  // 导出操作
  async exportPost (data) {
    return request.post('/Business/Contribute/Export', data)
  }

  // 导入操作
  async importPost (data) {
    return request.post('/Business/Contribute/Import', data)
  }
}
