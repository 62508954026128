import BaseController from '@/service/BaseService'
import ContributeRequest from '@/request/Business/ContributeRequest'
import { confirmTip, errorTip, successTip } from '@/utils/Tip'

const base = new BaseController()

export default {
  data () {
    return {
      showDialog: false, // 是否显示弹窗
      tableLoad: false, // 表格加载
      scene: 0, // 场景: 0表示弹框为添加，1表示弹框为修改
      // 表单信息
      form: {
        name: '',
        phone: '',
        domain: '',
        thesis: '',
        email: '',
        pdf: '',
        word: '',
        educational_background: '',
        standing: '',
        participation_mode: ''
      },
      // 表单验证规则
      rules: {
        name: [{
          required: true,
          message: '请输入姓名'
        }],
        phone: [{
          required: true,
          message: '请输入联系方式'
        }],
        domain: [{
          required: true,
          message: '请输入网站域名'
        }],
        thesis: [{
          required: true,
          message: '请输入论文标题'
        }],
        email: [{
          required: true,
          message: '请输入邮箱'
        }],
        word: [{
          required: true,
          message: '请输入word'
        }]
      },

      // 分页信息[前端控制单页数量]
      pageData: {
        page: 1,
        limit: 10,
        total: 0,
        list: [],
        select: {
          status: {}
        }
      },

      // 表单筛选
      searchForm: {
        name: null,
        phone: null,
        domain: null,
        thesis: null,
        email: null,
        educational_background: null,
        standing: null,
        participation_mode: null,
        status: null
      }
    }
  },
  // 初始化函数
  created: async function () {
    await base.init(this, new ContributeRequest()).getList(this.pageData, this.searchForm)
  },
  methods: {
    // 导入事件
    importClick: async function ({ file }) {
      await base.importClick(file)
    },
    // 导出事件
    exportClick: async function () {
      await base.exportClick()
    },
    // 提交事件
    submitClick: async function () {
      await base.submitClick()
    },
    // 取消事件
    cancelDialog: async function () {
      await base.cancelDialog()
    },
    // 删除事件
    deleteClick: async function (item) {
      await base.deleteClick(item)
    },
    // 核销
    handleClick: async function (item) {
      // 询问框
      const status = await confirmTip(this, '确定核销吗?')
      if (!status) return false
      // 发起请求
      const result = await base.request.handlePost(item)
      if (result.code !== 200) errorTip(this, result.message)
      // 更新表格数据
      await base.getList(this.pageData, this.searchForm)
      successTip(this, result.message)
    },
    // 搜索事件
    searchClick: async function () {
      await base.getList()
    },
    // 重置事件
    resetClick: async function () {
      await base.resetClick()
    },
    // 添加事件
    addClick: function () {
      base.addClick()
    },
    // 分页事件
    pagingClick: async function (val) {
      await base.pagingClick(val)
    },
    // 编辑事件
    editClick: async function (item) {
      await base.editClick(item)
    },
    // 子组件修改父组件data
    changDataValue: function (field, value) {
      this.form[field] = value
    }
  }
}
