<template>
  <div class="manage">

    <!--    搜索区域开始   -->
    <el-collapse>
      <el-collapse-item title="搜索" name="1">
        <el-form :model="searchForm"
                 :inline="true"
                 label-width="80px">
          <el-form-item label="姓名" prop="name">
            <el-input placeholder="请输入姓名" v-model="searchForm.name"/>
          </el-form-item>
          <el-form-item label="联系方式" prop="phone">
            <el-input placeholder="请输入联系方式" v-model="searchForm.phone"/>
          </el-form-item>
          <el-form-item label="网站域名" prop="domain">
            <el-input placeholder="请输入网站域名" v-model="searchForm.domain"/>
          </el-form-item>
          <el-form-item label="论文标题" prop="thesis">
            <el-input placeholder="请输入论文标题" v-model="searchForm.thesis"/>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input placeholder="请输入邮箱" v-model="searchForm.email"/>
          </el-form-item>
          <el-form-item label="学历" prop="educational_background">
            <el-input placeholder="请输入学历" v-model="searchForm.educational_background"/>
          </el-form-item>
          <el-form-item label="身份" prop="standing">
            <el-input placeholder="请输入身份" v-model="searchForm.standing"/>
          </el-form-item>
          <el-form-item label="参与方式" prop="participation_mode">
            <el-input placeholder="请输入参与方式" v-model="searchForm.participation_mode"/>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="searchForm.status" placeholder="请选择状态">
              <el-option
                v-for="(item, index) in pageData.select.status"
                :key="index"
                :label="item"
                :value="index*1">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>
    <!--    搜索区域结束   -->

    <!--    分割线-->
    <el-divider></el-divider>

    <!-- 弹窗区域开始 -->
    <el-dialog
      :title="this.scene === 1 ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="50%"
      :before-close="cancelDialog">
      <!-- 用户的表单信息 -->
      <el-form
        ref="form"
        :inline="false"
        :model="form"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="姓名" prop="name">
          <el-input placeholder="请输入姓名" v-model="form.name"/>
        </el-form-item>
        <el-form-item label="联系方式" prop="phone">
          <el-input placeholder="请输入联系方式" v-model="form.phone"/>
        </el-form-item>
        <el-form-item label="网站域名" prop="domain">
          <el-input placeholder="请输入网站域名" v-model="form.domain"/>
        </el-form-item>
        <el-form-item label="论文标题" prop="thesis">
          <el-input placeholder="请输入论文标题" v-model="form.thesis"/>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input placeholder="请输入邮箱" v-model="form.email"/>
        </el-form-item>
        <el-form-item label="pdf" prop="pdf">
          <el-input placeholder="请输入pdf" v-model="form.pdf"/>
        </el-form-item>
        <el-form-item label="word" prop="word">
          <el-input placeholder="请输入word" v-model="form.word"/>
        </el-form-item>
        <el-form-item label="学历" prop="educational_background">
          <el-input placeholder="请输入学历" v-model="form.educational_background"/>
        </el-form-item>
        <el-form-item label="身份" prop="standing">
          <el-input placeholder="请输入身份" v-model="form.standing"/>
        </el-form-item>
        <el-form-item label="参与方式" prop="participation_mode">
          <el-input placeholder="请输入参与方式" v-model="form.participation_mode"/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog">取 消</el-button>
          <el-button type="primary" @click="submitClick">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 弹窗区域结束 -->

    <!-- 表头按钮区域开始 -->
    <div class="manage-header">
      <!--      <el-button size="mini" class="el-icon-plus" type="primary" @click="addClick" plain>新增</el-button>-->
      <el-button size="mini" class="el-icon-search" type="success" @click="searchClick" plain>搜索</el-button>
      <el-button size="mini" class="el-icon-refresh-right" type="warning" @click="resetClick" plain>重置</el-button>
      <!--      <el-button size="mini" class="el-icon-download" type="info" @click="exportClick" plain>导出</el-button>
            <el-upload action="#"
                       :show-file-list="false"
                       :http-request="importClick">
              <el-button size="mini" class="el-icon-upload" type="info" plain>导入</el-button>
            </el-upload>-->
    </div>
    <!-- 表头按钮区域结束 -->

    <!-- 表格 -->
    <el-table
      :data="pageData.list"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      v-loading="tableLoad">
      <el-table-column prop="id" label="id"/>
      <el-table-column prop="name" label="姓名"/>
      <el-table-column prop="phone" label="联系方式"/>
      <el-table-column prop="domain" label="网站域名">
        <template v-slot="{row}">
          <a :href="`http://${row.domain}/`" target="_blank">{{row.domain}}</a>
        </template>
      </el-table-column>
      <el-table-column prop="thesis" label="论文标题" class="thesis"/>
      <el-table-column prop="email" label="邮箱"/>
      <el-table-column label="pdf">
        <template v-slot="{row}">
          <a v-if="row.pdf" :href="row.pdf" download="投稿.pdf">下载</a>
        </template>
      </el-table-column>
      <el-table-column prop="word" label="word">
        <template v-slot="{row}">
          <a v-if="row.word" :href="row.word" download="投稿.word">下载</a>
        </template>
      </el-table-column>
      <el-table-column prop="educational_background" label="学历"/>
      <el-table-column prop="standing" label="身份"/>
      <el-table-column prop="participation_mode" label="参与方式"/>
      <el-table-column prop="status_text" label="状态"/>
      <el-table-column prop="createTime" label="创建时间"/>
      <el-table-column prop="updateTime" label="修改时间"/>
      <el-table-column label="操作">
        <template v-slot="{row}">
          <div>
            <el-button size="mini" @click="handleClick(row)" type="warning" v-if="row.status === 1">核销</el-button>
            <!--            <el-button size="mini" @click="editClick(row)">编辑</el-button>-->
            <!--            <el-button size="mini" @click="deleteClick(row)" type="danger">删除</el-button>-->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div>
      <el-pagination
        layout="prev, pager, next"
        :total="pageData.total"
        @current-change="pagingClick"/>
    </div>
  </div>
</template>

<script>
import '@/assets/static/css/base.css'
import service from '@/service/Business/ContributeService'

export default service
</script>
